const React = require('react')

const { DomainContextProvider } = require('./src/context/DomainContext')

exports.disableCorePrefetching = () => true

exports.wrapRootElement = ({ element }) => {
	return <DomainContextProvider>{element}</DomainContextProvider>
}

