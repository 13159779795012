import React, { useEffect, useState } from 'react'

export const DomainContext = React.createContext({ domain: null })

export function DomainContextProvider({ children }) {
	const [domain, setDomain] = useState()

	useEffect(() => {
		setDomain(window.location.hostname)
	}, [])

	return <DomainContext.Provider value={{ domain }}>{children}</DomainContext.Provider>
}
